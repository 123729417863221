declare global {
  interface Window {
    config?: {
      REACT_APP_API_CES_AUTH: string;
      REACT_APP_UI_CES_AUTH: string;
      REACT_APP_OKTA_AUTH_ISSUER: string;
      REACT_APP_OKTA_AUTH_CLIENT_ID: string;
      REACT_APP_CES_AUTH_REDIRECT_URI: string;
      REACT_APP_SEGMENT_WRITE_KEY: string;
      REACT_APP_GRAFANA_URL: string;
      REACT_APP_GRAFANA_APP_NAME: string;
      REACT_APP_GRAFANA_APP_VERSION: string;
      REACT_APP_GRAFANA_APP_ENV: string;
    };
  }
}

type EnvKey =
  | "API_CES_AUTH"
  | "UI_CES_AUTH"
  | "OKTA_AUTH_ISSUER"
  | "OKTA_AUTH_CLIENT_ID"
  | "CES_AUTH_REDIRECT_URI"
  | "SEGMENT_WRITE_KEY"
  | "INCOMPLETE_FEATURE"
  | "GRAFANA_URL"
  | "GRAFANA_APP_NAME"
  | "GRAFANA_APP_VERSION"
  | "GRAFANA_APP_ENV";

const PROCESS_ENV: Record<EnvKey, string> = {
  API_CES_AUTH: process.env.REACT_APP_API_CES_AUTH || "",
  UI_CES_AUTH: process.env.REACT_APP_UI_CES_AUTH || "",
  OKTA_AUTH_ISSUER: process.env.REACT_APP_OKTA_AUTH_ISSUER || "",
  OKTA_AUTH_CLIENT_ID: process.env.REACT_APP_OKTA_AUTH_CLIENT_ID || "",
  CES_AUTH_REDIRECT_URI: process.env.REACT_APP_CES_AUTH_REDIRECT_URI || "",
  SEGMENT_WRITE_KEY: process.env.REACT_APP_SEGMENT_WRITE_KEY || "",
  INCOMPLETE_FEATURE: process.env.REACT_APP_INCOMPLETE_FEATURE || "",
  GRAFANA_URL: process.env.REACT_APP_GRAFANA_URL || "",
  GRAFANA_APP_NAME: process.env.REACT_APP_GRAFANA_APP_NAME || "",
  GRAFANA_APP_VERSION: process.env.REACT_APP_GRAFANA_APP_VERSION || "",
  GRAFANA_APP_ENV: process.env.REACT_APP_GRAFANA_APP_ENV || ""
};

const getServerEnv = (key: EnvKey) => {
  if (typeof window === "undefined" || !window?.config) {
    return "";
  }

  return window.config[`REACT_APP_${key}`] || "";
};

const getProcessEnv = (key: EnvKey) => {
  return PROCESS_ENV[key];
};

const getEnv = (key: EnvKey) => {
  return getServerEnv(key) || getProcessEnv(key);
};

export class ENV {
  static get API_CES_AUTH(): string {
    return getEnv("API_CES_AUTH");
  }

  static get SEGMENT_WRITE_KEY(): string {
    return getEnv("SEGMENT_WRITE_KEY");
  }

  static get UI_CES_AUTH(): string {
    return getEnv("UI_CES_AUTH");
  }

  static get OKTA_AUTH_ISSUER(): string {
    return getEnv("OKTA_AUTH_ISSUER");
  }

  static get OKTA_AUTH_CLIENT_ID(): string {
    return getEnv("OKTA_AUTH_CLIENT_ID");
  }

  static get CES_AUTH_REDIRECT_URI(): string {
    return getEnv("CES_AUTH_REDIRECT_URI");
  }

  static get INCOMPLETE_FEATURE(): string {
    return getEnv("INCOMPLETE_FEATURE");
  }

  static get GRAFANA_URL(): string {
    return getEnv("GRAFANA_URL");
  }

  static get GRAFANA_APP_NAME(): string {
    return getEnv("GRAFANA_APP_NAME");
  }

  static get GRAFANA_APP_VERSION(): string {
    return getEnv("GRAFANA_APP_VERSION");
  }

  static get GRAFANA_APP_ENV(): string {
    return getEnv("GRAFANA_APP_ENV");
  }

  static get SESSION_EXPIRED_PAGE_URL(): string {
    return `${ENV.UI_CES_AUTH}/session-expired`;
  }
}
