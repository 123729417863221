import { useTranslation } from "locales/i18n";
import { useCallback, useState } from "react";

type Message = {
  type: "success" | "error" | "info";
  title: string;
  description: string;
};

type MessageContent = Partial<Omit<Message, "type">>;

export type UseMessage = {
  message?: Message;
  setSuccessMessage: (message?: MessageContent) => void;
  setErrorMessage: (message?: MessageContent) => void;
  setInfoMessage: (message?: MessageContent) => void;
  setMessage: (message?: MessageContent) => void;
  clearMessage: () => void;
};

type DefaultDescription = {
  error?: string;
  success?: string;
  info?: string;
};

const useMessage = ({
  description
}: {
  description?: DefaultDescription;
} = {}): UseMessage => {
  const [t] = useTranslation();

  const [promptMessage, setPromptMessage] = useState<Message>();

  const setSuccessMessage = useCallback(
    (message?: MessageContent) => {
      setPromptMessage({
        type: "success",
        title: message?.title || t("success"),
        description: message?.description || description?.success || "",
        ...message
      });
    },
    [description?.success, t]
  );

  const setErrorMessage = useCallback(
    (message?: MessageContent) => {
      setPromptMessage({
        type: "error",
        title: t("oh-no"),
        ...message,
        description:
          message?.description ||
          description?.error ||
          t("there-is-a-technical-error")
      });
    },
    [description?.error, t]
  );

  const setInfoMessage = useCallback(
    (message?: MessageContent) => {
      setPromptMessage({
        type: "info",
        title: t("information"),
        description: description?.info || "",
        ...message
      });
    },
    [description?.info, t]
  );
  const setMessage = useCallback((message?: MessageContent) => {
    setPromptMessage({
      type: "info",
      title: String(message?.title),
      description: String(message?.description)
    });
  }, []);

  const clearMessage = useCallback(() => {
    setPromptMessage(undefined);
  }, []);

  return {
    message: promptMessage,
    setSuccessMessage,
    setErrorMessage,
    setInfoMessage,
    setMessage,
    clearMessage
  };
};

export default useMessage;
