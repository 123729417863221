import { faro, LogLevel } from "@grafana/faro-react";
var useExternalLog = function () {
    var logInfo = function (_a) {
        var label = _a.label, message = _a.message, _b = _a.skipDedupe, skipDedupe = _b === void 0 ? true : _b;
        if (!faro.config) {
            return;
        }
        faro.api.pushLog([label], {
            level: LogLevel.INFO,
            context: {
                message: message
            },
            skipDedupe: skipDedupe
        });
    };
    var logWarn = function (_a) {
        var label = _a.label, message = _a.message, _b = _a.skipDedupe, skipDedupe = _b === void 0 ? true : _b;
        if (!faro.config) {
            return;
        }
        faro.api.pushLog([label], {
            level: LogLevel.WARN,
            context: {
                message: message
            },
            skipDedupe: skipDedupe
        });
    };
    var logError = function (_a) {
        var label = _a.label, message = _a.message, _b = _a.skipDedupe, skipDedupe = _b === void 0 ? true : _b;
        if (!faro.config) {
            return;
        }
        faro.api.pushLog([label], {
            level: LogLevel.ERROR,
            context: {
                message: message
            },
            skipDedupe: skipDedupe
        });
    };
    var pushError = function (_a) {
        var label = _a.label, message = _a.message, _b = _a.skipDedupe, skipDedupe = _b === void 0 ? true : _b;
        if (!faro.config) {
            return;
        }
        faro.api.pushError(new Error(label), {
            context: {
                message: message
            },
            skipDedupe: skipDedupe
        });
    };
    return { logInfo: logInfo, logWarn: logWarn, logError: logError, pushError: pushError };
};
export default useExternalLog;
