import cesTrack from "@cheetahdigital/ces-track";
import { initCesAuth, redirectToLogin } from "@cheetahdigital/cesauth-core-lib";
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-react";
import AuthContainer from "components/AuthContainer";
import CesAuthSecureRoute from "components/Login/CesAuthSecureRoute";
import LoginFailureChallenge from "components/Login/LoginFailureChallenge";
import OktaAuthProvider from "components/Login/OktaAuthProvider";
import MessageProvider from "components/shared/Message/MessageProvider";
import Navbar from "components/shared/Navbar";
import RememberSearchProvider from "components/shared/RememberSearchProvider";
import { FC, lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import AppBackground from "./AppBackground";
import { ENV } from "./constants";

const Profile = lazy(() => import("components/Profile"));
const Adm = lazy(() => import("components/Adm"));
const Management = lazy(() => import("components/Management"));
const Login = lazy(() => import("components/Login"));
const LoginCallback = lazy(() => import("components/Login/LoginCallback"));
const Error = lazy(() => import("components/Login/Error"));
const LogoutSuccess = lazy(() => import("components/Login/LogoutSuccess"));
const OktaState = lazy(() => import("components/OktaState"));
const ChangeEmailSuccess = lazy(
  () => import("components/Login/ChangeEmailSuccess")
);
const SessionExpired = lazy(() => import("components/Login/SessionExpired"));
const AccountUpgraded = lazy(() => import("components/Login/AccountUpgraded"));
const SignUp = lazy(() => import("components/SignUp"));
const ForgotPassword = lazy(() => import("components/ForgotPassword"));
const ResetPassword = lazy(() => import("components/ResetPassword"));
const PasswordExpired = lazy(() => import("components/PasswordExpired"));
const SelfServiceEmail = lazy(() => import("components/SelfServiceEmail"));

const ActivationSupport = lazy(
  () => import("components/Login/ActivationSupport")
);
const ActivationWithCode = lazy(
  () => import("components/Login/ActivateWithCode")
);

if (ENV.GRAFANA_URL.includes("grafana")) {
  initializeFaro({
    url: ENV.GRAFANA_URL,
    app: {
      name: ENV.GRAFANA_APP_NAME,
      version: ENV.GRAFANA_APP_VERSION,
      environment: ENV.GRAFANA_APP_ENV
    },
    instrumentations: [...getWebInstrumentations()]
  });
}

initCesAuth({
  authBaseURL: ENV.API_CES_AUTH,
  sessionExpiredPageUrl: ENV.SESSION_EXPIRED_PAGE_URL,
  isHandleInactivity: false,
  isRedirectOnSessionExpired: false,
  isFetchTokenInBackground: false,
  isLoginAs: true
});

cesTrack.init({
  segmentWriteKey: ENV.SEGMENT_WRITE_KEY,
  settings: {
    autoIdentify: false,
    autoPage: false
  }
});

const App: FC = () => {
  useEffect(() => {
    const onSessionExpired = () => {
      if (
        window.location.pathname === "/error" ||
        window.location.pathname === "/login" ||
        window.location.pathname === "/login-by-email" ||
        window.location.pathname === "/login-with-credential" ||
        window.location.pathname === "/session-expired" ||
        window.location.pathname === "/logout-successful" ||
        window.location.pathname === "/change-email-successful" ||
        window.location.pathname === "/login/callback" ||
        window.location.pathname === "/signup" ||
        window.location.pathname === "/forgot-password" ||
        window.location.pathname === "/reset-password" ||
        window.location.pathname === "/state"
      ) {
        return;
      }

      redirectToLogin();
    };

    window.addEventListener("cesAuthOnSessionExpired", onSessionExpired);

    return () => {
      window.removeEventListener("cesAuthOnSessionExpired", onSessionExpired);
    };
  }, []);

  return (
    <Router>
      <Suspense fallback={<></>}>
        <AppBackground>
          <OktaAuthProvider>
            <MessageProvider>
              <Route exact path="/login">
                <Login />
              </Route>

              <Route path="/login-attempts-exceeded">
                <LoginFailureChallenge />
              </Route>

              <Route path="/login-by-email">
                <Login isPasswordless />
              </Route>

              <Route path="/login-with-credential">
                <Login isLoginWithCredential />
              </Route>

              <Route path="/logout-successful">
                <LogoutSuccess />
              </Route>

              <Route path="/state">
                <OktaState />
              </Route>

              <Route path="/test-email">
                <SelfServiceEmail />
              </Route>

              <Route path="/change-email-successful">
                <ChangeEmailSuccess />
              </Route>

              <Route path="/login/callback">
                <LoginCallback />
              </Route>

              <Route path="/session-expired">
                <AuthContainer>
                  <SessionExpired />
                </AuthContainer>
              </Route>

              <Route path="/account-upgraded">
                <AccountUpgraded />
              </Route>

              <Route path="/activation-support">
                <ActivationSupport />
              </Route>

              <Route path="/activation-with-code">
                <ActivationWithCode />
              </Route>

              <Route path="/error">
                <Error />
              </Route>

              <Route path="/signup">
                <SignUp />
              </Route>

              <Route path="/forgot-password">
                <AuthContainer isScrollable={true}>
                  <ForgotPassword />
                </AuthContainer>
              </Route>

              <Route path="/reset-password">
                <AuthContainer isScrollable={true}>
                  <ResetPassword />
                </AuthContainer>
              </Route>

              <Route path="/password-expired">
                <PasswordExpired />
              </Route>

              <RememberSearchProvider>
                <CesAuthSecureRoute path="/adm">
                  <Adm />
                </CesAuthSecureRoute>

                <CesAuthSecureRoute path="/m">
                  <Management />
                </CesAuthSecureRoute>
              </RememberSearchProvider>

              <CesAuthSecureRoute path="/profile">
                <Navbar app="profile">
                  <Profile />
                </Navbar>
              </CesAuthSecureRoute>

              <Route path="/" exact>
                <Redirect to="/login" />
              </Route>
            </MessageProvider>
          </OktaAuthProvider>
        </AppBackground>
      </Suspense>
    </Router>
  );
};

export default App;
